import React from 'react';
import { contactContent } from '../../content/index';

const ContactCard = ({
  contact,
}: {
  contact: typeof contactContent.contacts.cards[number];
}) => {
  return (
    <div
      data-testid="contact-card"
      className=" text-center text-14px leading-5 md:leading-7"
    >
      <div data-testid="contact-name" className="font-semibold md:text-18px">
        {contact.name}
      </div>
      <div
        data-testid="contact-title"
        className="uppercase font-semibold text-darkGrey xl:text-16px"
      >
        {contact.title}
      </div>
      <a
        data-testid="contact-email"
        className="text-regalBlue xl:text-16px"
        href={`mailto:${contact.email}`}
      >
        {contact.email}
      </a>
    </div>
  );
};

const Contacts = () => {
  const { cards, president } = contactContent.contacts;
  return (
    <div data-testid="contacts-container" className="pb-7 pt-7 md:pt-9 md:pb-9">
      <div
        data-testid="president-desktop-contact-container"
        className="md:block hidden"
      >
        <ContactCard contact={president} />
      </div>
      <div className="flex">
        <div
          className="md:grid grid-cols-2 grid-rows-3 gap-x-5 flex flex-col mx-auto md:w-600px"
          data-testid="contact-list"
        >
          <div
            data-testid="president-mobile-contact-container"
            className="md:hidden"
          >
            <ContactCard contact={president} />
          </div>
          {cards.map((contact, index) => (
            <div
              key={contact.email}
              className={`pt-7 md:pt-20 ${
                index === cards.length - 1 &&
                cards.length % 2 !== 0 &&
                'col-span-2'
              }`}
            >
              <ContactCard contact={contact} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contacts;
