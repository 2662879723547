import React from 'react';
import classNames from 'classnames';
import emailIconWhite from '../../assets/contact/email-icon-white.png';
import emailIconBlack from '../../assets/contact/email-icon-black.png';

function EmailButton({ email }: { email: any }) {
  const buttonClass = classNames(
    'md:px-4 px-19px py-15px',
    'border border-black rounded',
    'bg-secondary pointer-fine:hover:bg-white',
    'border-opacity-0 pointer-fine:hover:border-opacity-100',
    'text-white pointer-fine:hover:text-black',
    'transform duration-200 ease-in group',
  );

  return (
    <a href={`mailto:${email}`} data-testid="emailButton">
      <button className={buttonClass}>
        <div className="flex justify-center items-center space-x-3">
          <div className="flex-none relative">
            <img
              className="absolute object-contain opacity-0 transform duration-200 ease-in pointer-fine:group-hover:opacity-100"
              src={emailIconBlack}
              alt="Envelope"
            />
            <img src={emailIconWhite} alt="Envelope" />
          </div>
          <div className="text-sm font-semibold">{email}</div>
        </div>
      </button>
    </a>
  );
}

export default EmailButton;
