import React from 'react';
import { contactContent } from '../../content/index';
import EmailButton from './EmailButton';

function Email() {
  const { header, description, cta } = contactContent.email;

  return (
    <div data-testid="email-container" className="flex flex-col">
      <div className="mx-auto md:text-2xl md:font-bold font-semibold">
        {header}
      </div>
      <div className="mt-1 mx-auto md:text-base text-sm">
        <span className="hidden md:inline">{description.md}</span>
        <span className="md:hidden">{description.sm}</span>
      </div>
      <div className="md:hidden block mx-auto mt-6">
        <EmailButton email={cta.mobile} />
      </div>
      <div className="md:block hidden  mx-auto mt-7">
        <EmailButton email={cta.desktop} />
      </div>
    </div>
  );
}

export default Email;
