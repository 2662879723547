import React from 'react';
import ContentImageTile from '../ContentImageTile';
import { contactContent } from '../../content/index';

const Offices = () => {
  const { header, offices } = contactContent;
  const officeAddressClass = 'text-14px xl:text-16px text-gray leading-5';

  return (
    <div data-testid="officesContainer">
      <div className="grid sm:grid-cols-2 grid-cols-1 sm:grid-rows-2 grid-rows-4 sm:gap-x-4 gap-y-7 sm:mb-4">
        {offices.map(office => (
          <div data-testid="officeCard" key={office.title} className="w-full">
            <div
              className="sm:hidden aspect-w-1 aspect-h-1"
              data-testid="officeImagesMobile"
            >
              <div className="w-full h-full">
                <ContentImageTile
                  imagePath={`../../contact/offices/${office.images.mobile.file}`}
                  alt={office.images.mobile.alt}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div
              className="sm:flex lg:flex-row flex-col hidden lg:h-425px md:h-585px sm:h-425px lg:space-x-2 lg:space-y-0 space-y-2.5"
              data-testid="officeImages"
            >
              <ContentImageTile
                className="justify-self-stretch w-full h-full"
                imagePath={`../../contact/offices/${office.images.desktop.first.file}`}
                alt={office.images.desktop.first.alt}
              />
              {office.images.desktop.second && (
                <ContentImageTile
                  className="justify-self-stretch lg:w-56% lg:h-auto h-full"
                  imagePath={`../../contact/offices/${office.images.desktop.second.file}`}
                  alt={office.images.desktop.second.alt}
                />
              )}
            </div>
            <div className="p-2  sm:px-0">
              <h2 className="my-2.5 font-semibold text-18px">{office.title}</h2>
              {office.phone && (
                <div className="mb-1 text-14px xl:text-16px">
                  <a href={`tel:${office.phone}`}>{office.phone}</a>
                </div>
              )}
              <p className={officeAddressClass}>{office.street}</p>
              <p className={officeAddressClass}>{office.city}</p>
              <p className={officeAddressClass}>{office.postal}</p>
              <p className={officeAddressClass}>{office.country}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="justify-center items-center mt-10 sm:mt-12">
        <h2
          data-testid="officesHeader"
          className="text-center font-bold text-24px md:text-32px"
        >
          {header}
        </h2>
      </div>
    </div>
  );
};

export default Offices;
